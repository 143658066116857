import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PUBLIC_SURVEY_CHECKOUT } from 'Consts/routes';

import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Head from 'Components/core/Head';
import Button from 'Components/layout/Button';

export default class PublicSurvey extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    state = {};

    onSelect = () => {
        const { history } = this.props;
        history.push(PUBLIC_SURVEY_CHECKOUT.path);
    }

    render() {
        return (
            <StyledComponent 
                className="component-survey-trainer-page" 
                styles={require('./styles')}
            >
                <Head
                    title="meta.title"
                    description="meta.description"
                />
                <div 
                    className="trainer-photo-bg" 
                    style={{ backgroundImage: `url(${require('Theme/images/placeholders/trainer-bg.png')})` }}
                />
                <LayoutContainer>
                    <div className="trainer-panel">
                        <div className="trainer-panel-content">
                            <h1 className="panel-title">Twój trener</h1>
                            <h2 className="panel-subtitle">Michał</h2>
                            <p className="panel-description">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi consequat blandit enim fermentum ultricies. 
                            Phasellus non tortor eget erat ultrices ullamcorper a in purus. Duis congue dapibus vehicula. Aenean leo lectus, 
                            dignissim a aliquet gravida, mollis nec orci. Sed id turpis tempor, feugiat ante quis, mollis sapien. 
                            Fusce nec condimentum ligula, vel dignissim sem. Nam imperdiet lorem eget diam volutpat fringilla. 
                            Vestibulum sodales erat molestie, congue lorem nec, mollis odio. Morbi molestie iaculis tellus, in vulputate 
                            arcu imperdiet dictum. Nulla venenatis auctor mattis. Nulla auctor lorem elementum, molestie magna id, interdum velit. 
                            Maecenas sit amet accumsan augue, sed laoreet nisi. Nulla dictum ac dolor vitae vehicula.
                            </p>
                            <div className="panel-controls">
                                <Button size="large" onClick={this.onSelect}>Wybieram</Button>
                            </div>
                        </div>
                        <p className="trainer-panel-disclaimer">
                        Pamiętaj, że w każdej chwilii możesz zmienić swojego trenera.
                        </p>
                    </div>
                </LayoutContainer>
            </StyledComponent>
        );
    }
}
