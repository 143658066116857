import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css({
        minHeight: '85vh',
        position: 'relative',
        
        '.trainer-photo-bg': {
            zIndex: 1,
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        },

        '.trainer-panel': {
            zIndex: 2,
            position: 'relative',
            width: '30%',
            top: '5em',

            '.trainer-panel-content': {
                padding: '2em',
                background: '#333333',
                color: 'white',
                textAlign: 'left',
                boxShadow: '0 0 1em 0 rgba(0,0,0,.2)',
                border: '.3em solid white',

                '.panel-title': {
                    fontSize: '1.4em',
                    fontWeight: 300,
                },
                '.panel-subtitle': {
                    fontSize: '2em',
                    fontWeight: 600,
                    marginBottom: '1em',
                },
                '.panel-description': {
                    color: '#EEEEEE',
                    fontSize: '1em',
                    fontWeight: 300,
                },
                '.panel-controls': {
                    marginTop: '1em',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                },
            },
            '.trainer-panel-disclaimer': {
                color: 'white',
                marginTop: '1em',
                textAlign: 'center',
                textShadow: '0 .1em .3em black',
            },
        },
    });
